<template>
  <section
    id="filterCheckListTable"
    style="padding-top: 10px;"
  >
    <b-card-actions
      action-collapse
      title="건축물 대장 정보"
    >
      <b-row class="mt-2">
        <b-col
          md="12"
          xl="12"
        >
          <b-table-simple
            bordered
            class="rounded-bottom mb-0 filterCheckListTb"
            hover1
            small
            stacked
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  건축물 대장 정보 필터
                </b-th>
                <b-td stacked-heading="대지점유방식">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.PLAT_OCP_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.platOcpCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="소유방식">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.REGSTR_GB_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.regstrGbCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <!--              <b-tr>
                <b-td stacked-heading="소유자 유형">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.OWNER_TYPE_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.ownerTypeCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>-->
              <b-tr>
                <b-td stacked-heading="지역구분">
                  <span>
                    <b-form-radio
                      v-for="code in filterCommCodeData.RGN_GB_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.rgnGbCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                      @input="rgnGbCdChange($event)"
                    >
                      {{ code.cdNm }}
                    </b-form-radio>
                  </span>
                </b-td>
              </b-tr>
              <b-tr v-show="kmaAreaCdShowYn">
                <b-td stacked-heading="지역구분별">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.KMA_AREA_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.kmaAreaCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr v-show="sidoShowYn">
                <b-td stacked-heading="시도">
                  <span>
                    <b-form-radio
                      v-for="code in sidoList"
                      :key="code.sidoId"
                      v-model="filterFetchedInfo.sidoId"
                      :value="code"
                      class="checkbox-inline"
                      plain
                      @input="sidoChange($event)"
                    >
                      {{ code.sidoNm }}
                    </b-form-radio>
                  </span>
                </b-td>
              </b-tr>
              <b-tr v-show="sigunguShowYn">
                <b-td stacked-heading="시군구">
                  <span>
                    <b-form-checkbox
                      v-for="code in sigunguList"
                      :key="code.sigunguId"
                      v-model="filterFetchedInfo.sigunguId"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.sigunguNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <!--              <b-tr>
                <b-td stacked-heading="용도구분">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.PURPS_GB_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.purpsGbCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>-->
              <b-tr>
                <b-td stacked-heading="주용도">
									<span class="menuHelp" @mouseout="mout('mainPurpsCd')" @mouseover="mover('mainPurpsCd')">
										<img src="@/assets/images/logo/help.png" />
									</span>
									<span v-show="mainPurpsCdBoxFlag" class="menuHelpBox">
										건축법 제2조제2항에 따라 정의된 건축물의 용도
									</span>
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.MAIN_PURPS_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.mainPurpsCd"
                      :value="code"
                      class="checkbox-inline-width"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="대표용도(서울/고양시)">
                  <span class="menuHelp" @mouseout="mout('repPurpsCd')" @mouseover="mover('repPurpsCd')">
										<img src="@/assets/images/logo/help.png" />
									</span>
									<span v-show="repPurpsCdBoxFlag" class="menuHelpBox" style="left:245px !important;">
										건축물 대장 층별 개요에서 가장 큰 연면적을 차지하는 주용도
									</span>
									<span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.REP_PURPS_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.repPurpsCd"
                      :value="code"
                      class="checkbox-inline-width"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="세부용도(서울/고양시)">
                  <span class="menuHelp" @mouseout="mout('dtlPurpsCd')" @mouseover="mover('dtlPurpsCd')">
										<img src="@/assets/images/logo/help.png" />
									</span>
									<span v-show="dtlPurpsCdBoxFlag" class="menuHelpBox" style="left:145px !important;">
										건축법 시행령 제3조의 5에 따라 건축법 제2조의2항에서 정의된 주용도에 속하는 건축물의 세부용도
									</span>
									<span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.DTL_PURPS_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.dtlPurpsCd"
                      :value="code"
                      class="checkbox-inline-width"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>

              <!--              <b-tr>
                <b-td stacked-heading="층별용도 통일성">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.UNF_USE_FLR"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.unfUseFlr"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>-->
              <b-tr>
                <b-td stacked-heading="단열기준">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.INSLT_ST_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.insltStCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="연식기준">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.ANUAL_ST_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.anualStCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="연면적구분">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.TOTAREA_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.totareaCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <b-table-simple
            bordered
            class="rounded-bottom mb-0"
            hover
            small
            stacked
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  건축물 운영 정보 필터
                </b-th>
                <b-td>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label=""
                        label-for="useYyCd"
                      >
                        <filter-opnsvc-popup-renew :open-svc-json="filterFetchedInfo.openSvcJson" />
                      </b-form-group>

                      <!--                      <b-card
                                              v-if="filterInfo.OPEN_SVC_ID.length > 0"
                                              :border-variant="'dark'"
                                              :size="'sm'"
                                              class="shadow-none mt-1 mb-1 align-content-center"
                                              style="max-height: 300px; overflow-y: scroll"
                                              f-g
                                            >
                                              <b-badge
                                                v-for="(code, index) in filterInfo.OPEN_SVC_ID"
                                                :key="index"
                                                class="mr-1"
                                                variant="light-dark"
                                              >{{ code.bplcNm }}
                                              </b-badge>
                                            </b-card>-->
                    </b-col>
                  </b-row>
                </b-td>
                <!--                <b-td stacked-heading="연간 운영 일수">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.ANUAL_OPDYS_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.anualOpdysCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code }}
                    </b-form-checkbox>
                  </span>
                </b-td>-->
                <!--                <b-td stacked-heading="일평균 운영시간">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.AVRG_OPHRS_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.avrgOphrsCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>-->
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple
            bordered
            class="rounded-bottom mb-0"
            hover
            small
            stacked
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  에너지 사용량 필터
                </b-th>
                <b-td stacked-heading="소비연도코드">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.USE_YY_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.useYyCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
                <b-td stacked-heading="에너지원">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.ENGY_KIND_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.engyKindCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    v-if="code.cdNm !== '전체'"
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
                <b-td stacked-heading="사용용도">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.CLSF_KIND_CD"
                      :key="code.cdId"
                      v-model="filterInfo.CLSF_KIND_CD"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    v-if="code.cdNm !== '전체'"
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <!--          <b-table-simple
            hover
            small
            stacked
            bordered
            class="rounded-bottom mb-0"
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  시뮬레이션 데이터 필터
                </b-th>
                <b-td stacked-heading="" />
              </b-tr>

            </b-tbody>
          </b-table-simple>-->
          <!--          <b-table-simple
            hover
            small
            stacked
            bordered
            class="rounded-bottom mb-0"
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  소비수준 판단 지표 필터
                </b-th>
                <b-td stacked-heading="" />
              </b-tr>

            </b-tbody>
          </b-table-simple>-->
        </b-col>
      </b-row>
      <b-row style="padding-top: 10px;">
        <b-col
          md="6"
          xl="6"
        >
          <div
            class="form-label-group"
          >
            <b-form-input
              id="filterName"
              ref="filterName"
              v-model="filterFetchedInfo.filterNm"
              autocomplete="off"
              placeholder="저장할 필터명을 입력하세요."
              style="border: 2px solid #ccc;height: 40px"
            />
            <label for="filterName">저장할 필터명을 입력하세요.</label>
          </div>
        </b-col>
        <b-col
          md="3"
          xl="3"
        >
          <div>
            <b-button
              block
              variant="primary"
              @click="saveFilter('saveN')"
            >
              조회
            </b-button>
          </div>
        </b-col>
        <b-col
          md="3"
          xl="3"
        >
          <div>
            <b-button
              block
              variant="danger"
              @click="saveFilter('saveY')"
            >
              조회 후 저장
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-modal
      v-model="modalType"
      centered
      ok-only
      ok-title="확인"
      size="xl"
      title="필터조회내역"
    >
      <b-overlay
        :show="show"
        class="text-primary"
        rounded="xl"
      >
        <div>
          <filter-static-summary-table-renew />
          <filter-box-plot-chart-renew />
        </div>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BModal,
  BOverlay,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BTr,
  VBModal,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import { utils } from '@/utils/commUtils';
import FilterStaticSummaryTableRenew from './FilterStaticSummaryTableRenew.vue';
import FilterOpnsvcPopupRenew from './FilterOpnsvcPopupRenew.vue';
import FilterBoxPlotChartRenew from './FilterBoxPlotChartRenew.vue';

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormCheckbox,
    BFormGroup,
    FilterOpnsvcPopupRenew, // 업종 검색 팝업
    FilterStaticSummaryTableRenew,
    FilterBoxPlotChartRenew,
    BButton,
    BFormRadio,
    BFormInput,
    BModal,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      modalType: false,
      kmaAreaCdShowYn: false,
      sidoShowYn: false,
      sigunguShowYn: false,
      title: '',
      content: '',
      now: '00:00:00',

			mainPurpsCdBoxFlag: false,
			repPurpsCdBoxFlag: false,
			dtlPurpsCdBoxFlag: false,
    };
  },
  computed: {
    ...mapGetters({
      filterCommCodeData: 'filter/getFilterCommCodeData',
      filterInfo: 'filter/getSaveFilterInfo',
      sidoList: 'filter/getSidoList',
      sigunguList: 'filter/getSigunguList',
      // resultInfo: "filter/getResult",

      // 저장된 필터 리스트
      filterFetchedInfo: 'filter/getFilterFetchedInfo',
      show: 'filter/getShowOverlay',
    }),
  },
  mounted() {
    // console.log(this.$store.state.filterFetchedInfo)
  },
  created() {
    // 저장할 필터 정보 초기화
    this.$store.commit('filter/SET_RESET_SAVE_FILTER_INFO');

    // 공통코드 불러오기
    this.$store.dispatch('filter/FETCH_COMM_CODE_RENEW_DATA');

    // 시도 정보 불러오기
    this.$store.dispatch('filter/FETCH_SIDO_LIST');
  },
  updated() {
  },
  methods: {
    saveFilter(saveYn) {
      if (saveYn === 'saveY') {
        // 필터 저장 체크
        this.filterFetchedInfo.saveYn = 'Y';
        this.title = '저장하시겠습니까?';
        this.content = '설정된 필터 조건들이 저장됩니다.';
      } else {
        this.time();

        // 필터 저장 체크
        this.filterFetchedInfo.saveYn = 'N';
        this.title = '조회하시겠습니까?';
        this.content = '설정된 필터 조건들이 저장되지않습니다.';
        this.filterFetchedInfo.filterNm = `${this.now}`;
      }

      utils.fnConfirm(this, this.title, this.content)
        .then(async (res) => {
          if (res.value) { // 사용자가 확인 눌럿을 경우
            // 필수 조건 체크(저장 필터명에 값이 있어야 함)
            if (saveYn === 'saveY') {
              if (this.filterFetchedInfo.filterNm === '' || this.filterFetchedInfo.filterNm === undefined) {
                utils.fnAlert(this, '필터명을 입력해주세요.');
                return;
              }
            }
            // 필터 정보 저장
            await this.$store.dispatch('filter/SAVE_FILTER_RENEW_INFO', this.filterFetchedInfo);
            // const resFlag = await this.$store.getters["filter/getResult"];
            // utils.fnAlert(this, resFlag.resultMsg);

            this.modalType = !this.modalType;

            // 저장된 필터명으로 저장된 데이터 로드
            if (saveYn === 'saveY') {
              await this.$store.dispatch('filter/FETCH_FILTER_LOAD_DATA', this.filterFetchedInfo);
            } else { // 조회는 다른 스테이트로 진행 해야 됨
              if (this.filterFetchedInfo.BOXPLOT.cdGrpId !== '') {
                this.filterFetchedInfo.BOXPLOT = { label: '선택', cdGrpId: '', columnId: '' };
              }
              await this.$store.dispatch('filter/FETCH_FILTER_READ_DATA', this.filterFetchedInfo);
              this.filterFetchedInfo.filterNm = '';
            }
            await this.$store.dispatch('filter/FETCH_COMM_CODE_DATA_SUMMARY');
          } else {
            // 취소버튼 클릭시 필터명 초기화
            this.filterFetchedInfo.filterNm = '';
          }
        });
    },
    // 시도 변경시 시군구 데이터 조회
    sidoChange(sidoId) {
      //  this.filterFetchedInfo.sigunguId = [];

      // 선택된 값이 있는 경우 sigungu 데이터 조회
      this.$store.dispatch('filter/FETCH_SIGUNGU_LIST', sidoId.sidoId);
      this.sigunguShowYn = true;
    },
    // 지역구분 기준으로 show/hide 적용
    rgnGbCdChange(rgnGbCd) {
      if (rgnGbCd) {
        if (rgnGbCd.cdId === '1') {
          this.kmaAreaCdShowYn = true;
          this.sidoShowYn = false;
          this.sigunguShowYn = false;
          this.filterFetchedInfo.sidoId = [];
          this.filterFetchedInfo.sigunguId = [];
        } else {
          this.kmaAreaCdShowYn = false;
          this.sidoShowYn = true;
          this.filterFetchedInfo.kmaAreaCd = [];
        }
      }
    },
    time() {
      const date = new Date();
      this.now = `${date.getFullYear()}-${(date.getMonth() + 1) < 9 ? `0${date.getMonth() + 1}`
        : (date.getMonth() + 1)}-${(date.getDate()) < 9 ? `0${date.getDate()}`
        : (date.getDate())} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    },
		mover(val) {
      if (val === 'mainPurpsCd') this.mainPurpsCdBoxFlag = true;
			if (val === 'repPurpsCd') this.repPurpsCdBoxFlag = true;
			if (val === 'dtlPurpsCd') this.dtlPurpsCdBoxFlag = true;
    },
    mout(val) {
      if (val === 'mainPurpsCd') this.mainPurpsCdBoxFlag = false;
			if (val === 'repPurpsCd') this.repPurpsCdBoxFlag = false;
			if (val === 'dtlPurpsCd') this.dtlPurpsCdBoxFlag = false;
    },
  },

};
</script>

<style scoped>
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  width: 25% !important;
  border-right: 1px solid lightgray !important;
}

.checkbox-inline {
  display: inline-block;
  padding: 0 10px 0 0;
}

.checkbox-inline-width {
  display: inline-block;
  padding: 0 10px 0 0;
  width: 160px
}
</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.filterCheckListTb td{
	position:relative;
}
.menuHelp{
	cursor:pointer;
	position: absolute;
	margin-top:25px;
	margin-left:-45px;
	// top: 30px;
	// left: 417px;
	img{
		width:20px;
	}
	&Box{
		border: 3px solid #1560ad;
    padding: 10px;
    background: white;
		top: 55px;
		left: 270px;
    position: absolute;
    z-index: 10;
	}
}
</style>
